
import {
  mdiViewDashboard,
  mdiDatabase,
  mdiKey,
  mdiChartLineVariant
} from '@mdi/js';
import { mapState } from 'vuex';
import Snackbar from '../components/Snackbar';

export default {
  name: 'Dashboard',
  components: {
    Snackbar
  },
  data() {
    return {
      items: [
        { title: 'Models', icon: mdiViewDashboard, to: '/models' },
        { title: 'Datasets', icon: mdiDatabase, to: '/datasets' },
        { title: 'API Keys', icon: mdiKey, to: '/api-keys' },
        { title: 'Usage', icon: mdiChartLineVariant, to: '/usage' }
      ]
    };
  },
  computed: {
    ...mapState('user', ['user']),
    pageTitle() {
      const item = this.items.find((item) => item.to === this.$route.path);
      return item ? item.title : 'Profile';
    },
    shownName() {
      if (!this.user) {
        return '';
      } else {
        return this.user.full_name ? this.user.full_name : this.user.email;
      }
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch('user/logout');
      await this.$router.push({ path: '/login' });
    }
  }
};
