export default () => {
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = 'f2b69203-ab4e-4c1e-a738-1855cc98fe52';

  (function () {
    const d = document;
    const s = d.createElement('script');

    s.src = 'https://client.crisp.chat/l.js';
    s.async = 1;
    d.getElementsByTagName('head')[0].appendChild(s);
  })();
};
