/* eslint-disable */

export default ({ app }) => {
  /*
  ** Only run on client-side and only in production mode
  */
  if (process.env.NODE_ENV !== 'production') return
  /*
  ** Include Google Analytics Script
  */
  app.head.script.push({
    src: "https://www.googletagmanager.com/gtag/js?id=G-BRY8QK9SW6",
    async: true,
    defer: true,
  })

  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-BRY8QK9SW6');

  app.router.afterEach((to, from) => {
    /*
    ** We tell Google Tags to add a `pageview`
    */
    gtag('event', 'page_view', { send_to: 'G-BRY8QK9SW6'})
  })
}
