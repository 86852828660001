export const TYPE_RULES = {
  AUTHOR: {
    rules: 'required|not_null',
  },
  STRING: {
    rules: '',
  },
  DATE: {
    rules: 'date_validation',
  },
  NUMBER: {
    rules: 'decimal_validation',
  },
  VAT_ID: {
    rules: '',
  },
  CONSTANT: {
    rules: '',
  },
  CURRENCY: {
    rules: '',
  },
};

export const TYPE_HINTS = {
  DATE: 'Date format: YYYY-MM-DD',
  NUMBER: 'Number format, decimal or integer',
};

export const TRAINING_STATUSES = {
  IN_TRAINING: 'Models in training',
  TRAINING_FINISHED: 'Models trained',
  TRAINING_FAILED: 'Models training failed',
  TRAINING_ERROR: 'Error training model',
  UNTRAINED: 'Models not trained yet',
  DELETING: 'Deleting',
  DELETING_FAILED: 'Deleting failed',
  LOCKED: 'Model locked',
};

export const ACCOUNTLESS_INVOICE_STATUSES = {
  DATA_EXPIRED: 'Data expired',
  DATA_READY: 'Data is ready',
};

export const OCR_ENGINES = [
  {
    text: 'Default - Latin alphabet documents',
    value: '1',
  },
  {
    text: 'Multiple character sets - Latin, Cyrillic, Arabic',
    value: '2',
  },
];

export const SPECIFIC_OCR_ENGINES = {
  GENERAL_INVOICE_WITH_LINE_ITEMS: [
    {
      text: 'Default general line item invoice engine',
      value: '5',
    },
  ],
};

export const DOCUMENT_TYPE_PRESETS = {
  SIMPLE_INVOICE: {
    ocr_engine: '1',
    name: 'simple-invoice',
    fields: [
      {
        type: 'AUTHOR',
        name: 'supplier_name',
      },
      {
        name: 'invoice_number',
        type: 'STRING',
      },
      {
        name: 'pay_due_date',
        type: 'DATE',
      },
      {
        name: 'issue_date',
        type: 'DATE',
      },
      {
        name: 'total_amount',
        type: 'NUMBER',
      },
    ],
    line_items: [],
    plugins: [],
  },
  META_DATA_INVOICE: {
    ocr_engine: '1',
    name: 'metadata-invoice',
    fields: [
      {
        type: 'AUTHOR',
        name: 'supplier_name',
      },
      {
        name: 'receiver_name',
        type: 'STRING',
      },
      {
        name: 'invoice_number',
        type: 'STRING',
      },
      {
        name: 'purchase_order_number',
        type: 'STRING',
      },
      {
        name: 'pay_due_date',
        type: 'DATE',
      },
      {
        name: 'issue_date',
        type: 'DATE',
      },
      {
        name: 'total_amount',
        type: 'NUMBER',
      },
    ],
    line_items: [],
    plugins: [],
  },
  VAT_INVOICE: {
    ocr_engine: '1',
    name: 'vat-invoice',
    fields: [
      {
        type: 'AUTHOR',
        name: 'supplier_name',
      },
      {
        name: 'receiver_name',
        type: 'STRING',
      },
      {
        name: 'invoice_number',
        type: 'STRING',
      },
      {
        name: 'purchase_order_number',
        type: 'STRING',
      },
      {
        name: 'pay_due_date',
        type: 'DATE',
      },
      {
        name: 'issue_date',
        type: 'DATE',
      },
      {
        name: 'total_amount',
        type: 'NUMBER',
      },
      {
        name: 'net_amount',
        type: 'NUMBER',
      },
    ],
    line_items: [],
    plugins: [
      {
        name: 'VatRateNetPlugin',
        config: {
          net_amount_field: 'net_amount',
          gross_amount_field: 'total_amount',
        },
      },
    ],
  },
  INVOICE_WITH_LINE_ITEMS: {
    ocr_engine: '1',
    name: 'line-item-invoice',
    fields: [
      {
        type: 'AUTHOR',
        name: 'supplier_name',
      },
      {
        name: 'receiver_name',
        type: 'STRING',
      },
      {
        name: 'invoice_number',
        type: 'STRING',
      },
      {
        name: 'purchase_order_number',
        type: 'STRING',
      },
      {
        name: 'pay_due_date',
        type: 'DATE',
      },
      {
        name: 'issue_date',
        type: 'DATE',
      },
      {
        name: 'total_amount',
        type: 'NUMBER',
      },
    ],
    line_items: [
      { name: 'product_number', type: 'STRING' },
      { name: 'product_description', type: 'STRING' },
      { name: 'quantity', type: 'NUMBER' },
      { name: 'price', type: 'NUMBER' },
    ],
    plugins: [],
  },
  GENERAL_INVOICE_WITH_LINE_ITEMS: {
    ocr_engines: SPECIFIC_OCR_ENGINES.GENERAL_INVOICE_WITH_LINE_ITEMS,
    ocr_engine: '5',
    name: 'general-line-item-invoice',
    fields: [
      {
        name: 'supplier_name',
        type: 'AUTHOR',
      },
      {
        name: 'buyer_name',
        type: 'STRING',
      },
      {
        name: 'invoice_no',
        type: 'STRING',
      },
      {
        name: 'iban',
        type: 'STRING',
      },
      {
        name: 'vat_id_buyer',
        type: 'VAT_ID',
      },
      {
        name: 'vat_id_supplier',
        type: 'VAT_ID',
      },
      {
        name: 'date_of_service_start',
        type: 'DATE',
      },
      {
        name: 'date_of_service_end',
        type: 'DATE',
      },
      {
        name: 'due_date',
        type: 'DATE',
      },
      {
        name: 'invoice_date',
        type: 'DATE',
      },
      {
        name: 'amount_vat',
        type: 'NUMBER',
      },
      {
        name: 'amount_for_pay',
        type: 'NUMBER',
      },
      {
        name: 'amount_net',
        type: 'NUMBER',
      },
      {
        name: 'purchase_order',
        type: 'STRING',
      },
      {
        name: 'total_discount',
        type: 'NUMBER',
      },
    ],
    line_items: [
      { name: 'ident', type: 'STRING' },
      { name: 'ident_name', type: 'STRING' },
      { name: 'unit', type: 'STRING' },
      { name: 'amount', type: 'NUMBER' },
      { name: 'price', type: 'NUMBER' },
      { name: 'quantity', type: 'NUMBER' },
      { name: 'VAT_rate', type: 'NUMBER' },
    ],
    plugins: [],
  },
  TRANSPORT_REPORT: {
    ocr_engine: '1',
    name: 'transport-report',
    fields: [
      {
        type: 'AUTHOR',
        name: 'supplier_name',
      },
      {
        name: 'receiver_name',
        type: 'STRING',
      },
      {
        name: 'from_address',
        type: 'STRING',
      },
      {
        name: 'to_address',
        type: 'STRING',
      },
      {
        name: 'document_number',
        type: 'STRING',
      },
      {
        name: 'shipment_number',
        type: 'STRING',
      },
      {
        name: 'purchase_order_number',
        type: 'STRING',
      },
      {
        name: 'issue_date',
        type: 'DATE',
      },
      {
        name: 'load_weight',
        type: 'NUMBER',
      },
      {
        name: 'total_amount',
        type: 'NUMBER',
      },
    ],
    line_items: [
      { name: 'product_number', type: 'STRING' },
      { name: 'product_description', type: 'STRING' },
      { name: 'quantity', type: 'NUMBER' },
      { name: 'weight', type: 'NUMBER' },
      { name: 'number_of_boxes', type: 'NUMBER' },
    ],
    plugins: [],
  },
  US_TAX_REPORT: {
    ocr_engine: '1',
    name: 'tax-report-945',
    fields: [
      {
        type: 'AUTHOR',
        name: 'supplier_name',
      },
      {
        name: 'name',
        type: 'STRING',
      },
      {
        name: 'ein',
        type: 'STRING',
      },
      {
        name: 'trade_name',
        type: 'STRING',
      },
      {
        name: 'address',
        type: 'STRING',
      },
      {
        name: 'final_payment_date',
        type: 'DATE',
      },
      {
        name: 'federal_income_tax',
        type: 'NUMBER',
      },
      {
        name: 'backup_withholding',
        type: 'NUMBER',
      },
      {
        name: 'total_taxes',
        type: 'NUMBER',
      },
      {
        name: 'total_deposits',
        type: 'NUMBER',
      },
      {
        name: 'balance_due',
        type: 'NUMBER',
      },
      {
        name: 'overpayment',
        type: 'NUMBER',
      },
      {
        name: 'january_liability',
        type: 'NUMBER',
      },
      {
        name: 'february_liability',
        type: 'NUMBER',
      },
      {
        name: 'march_liability',
        type: 'NUMBER',
      },
      {
        name: 'april_liability',
        type: 'NUMBER',
      },
      {
        name: 'may_liability',
        type: 'NUMBER',
      },
      {
        name: 'june_liability',
        type: 'NUMBER',
      },
      {
        name: 'july_liability',
        type: 'NUMBER',
      },
      {
        name: 'august_liability',
        type: 'NUMBER',
      },
      {
        name: 'september_liability',
        type: 'NUMBER',
      },
      {
        name: 'october_liability',
        type: 'NUMBER',
      },
      {
        name: 'november_liability',
        type: 'NUMBER',
      },
      {
        name: 'december_liability',
        type: 'NUMBER',
      },
      {
        name: 'total_liability',
        type: 'NUMBER',
      },
      {
        name: 'type_name',
        type: 'STRING',
      },
      {
        name: 'type_date',
        type: 'DATE',
      },
      {
        name: 'ptin',
        type: 'STRING',
      },
      {
        name: 'firm_name',
        type: 'STRING',
      },
      {
        name: 'firm_address',
        type: 'STRING',
      },
      {
        name: 'firms_ein',
        type: 'STRING',
      },
      {
        name: 'phone_number',
        type: 'STRING',
      },
    ],
    line_items: [],
    plugins: [],
  },
  EUROPASS_CV: {
    ocr_engine: '1',
    name: 'europass-cv',
    fields: [
      {
        type: 'AUTHOR',
        name: 'supplier_name',
      },
      {
        name: 'name',
        type: 'STRING',
      },
      {
        name: 'address',
        type: 'STRING',
      },
      {
        name: 'email',
        type: 'STRING',
      },
      {
        name: 'phone',
        type: 'STRING',
      },
    ],
    line_items: [],
    plugins: [],
  },
};

export const NON_CONFIGURABLE_DOCUMENT_TYPES = ['general-line-item-invoice'];

export const EU_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'EL',
  'ES',
  'FI',
  'FR',
  'XI',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
  'GR',
];
