
import { mapState } from 'vuex';

export default {
  name: 'Document',
  computed: {
    ...mapState('user', ['user'])
  },
  methods: {
    async logout() {
      await this.$store.dispatch('user/logout');
      await this.$router.push({ path: '/login' });
    }
  }
};
