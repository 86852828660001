import { render, staticRenderFns } from "./document-type.vue?vue&type=template&id=629324a1&scoped=true&"
import script from "./document-type.vue?vue&type=script&lang=js&"
export * from "./document-type.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "629324a1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Snackbar: require('/home/runner/work/typless/typless/services/ui/components/Snackbar.vue').default})
