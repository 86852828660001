import { render, staticRenderFns } from "./authentication.vue?vue&type=template&id=6a103f46&"
import script from "./authentication.vue?vue&type=script&lang=js&"
export * from "./authentication.vue?vue&type=script&lang=js&"
import style0 from "./authentication.vue?vue&type=style&index=0&id=6a103f46&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/home/runner/work/typless/typless/services/ui/components/Footer.vue').default,Snackbar: require('/home/runner/work/typless/typless/services/ui/components/Snackbar.vue').default})
