import dayjs from 'dayjs';

import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(LocalizedFormat);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

const DayjsPlugin = {
  install(app) {
    app.config.globalProperties.$dayjs = dayjs;
  },
};

export default DayjsPlugin;
