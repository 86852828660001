import Auth from '@aws-amplify/auth';

const getAccessToken = async () => {
  const { accessToken } = await Auth.currentSession();
  return accessToken.getJwtToken();
};

export default function({ $axios, store, redirect }, inject) {
  const accountlessApi = $axios.create({
    headers: {
      common: {
        Accept: 'application/json, */*',
      },
    },
  });
  accountlessApi.setBaseURL(process.env.ACCOUNTLESS_API_URL);

  accountlessApi.interceptors.request.use(
    async (config) => {
      try {
        const accessToken = await getAccessToken(store, redirect);
        config.headers.Authorization = `Bearer ${accessToken}`;
      } catch (err) {
        store.dispatch('user/logout');
        redirect('/login');
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  inject('accountlessApi', accountlessApi);

  $axios.interceptors.request.use(
    async (config) => {
      if (['/api/v1/users'].includes(config.url)) return config;
      try {
        const accessToken = await getAccessToken(store, redirect);
        config.headers.Authorization = `Bearer ${accessToken}`;
      } catch (err) {
        store.dispatch('user/logout');
        redirect('/login');
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  $axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        store.dispatch('user/logout');
        redirect('/login');
      }
      return Promise.reject(error);
    }
  );
}
