
export default {
  name: 'Snackbar',
  data() {
    return {
      show: false,
      message: 'test',
      color: 'red',
      timeout: 6000
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'snackbar/showMessage') {
        this.message = state.snackbar.content;
        this.color = state.snackbar.color;
        this.timeout = state.snackbar.timeout || 6000;
        this.show = true;
      }
    });
  }
};
