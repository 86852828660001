/*eslint-disable*/

import isObject from "lodash.isobject";
import { extend, setInteractionMode } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';
import {EU_COUNTRIES} from "~/helpers/constants";
import { isValidPhoneNumber } from "libphonenumber-js";

setInteractionMode('eager');

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});

extend('regex', {
  ...rules.regex,
  message: '{_field_} format is invalid. Allowed characters are: letters, numbers and - / _'
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});

extend('date_validation', {
  validate(value) {
    let dateString;
    if (isObject(value)) {
      dateString = value.value
    } else {
      dateString = value;
    }
    // Allow null / empty strings to pass
    if (!dateString) return true;
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if(!dateString || !dateString.match(regEx)) return false;
    const d = new Date(dateString);
    const dNum = d.getTime();
    if(!dNum && dNum !== 0) return false;
    return d.toISOString().slice(0,10) === dateString;
  },
  message: 'Date must be in YYYY-MM-DD format.'
});

extend('phone_number', {
  params: ['prefix', 'prefixCountryCode'],
  validate(value, { prefix, prefixCountryCode }){
    if (!value && !prefix) {
      return true;
    }
    if (value &&  !prefix) {
      return false;
    }
    const phoneNumber = String(prefix.substring(1)) + String(value);
    return isValidPhoneNumber(phoneNumber, prefixCountryCode);
  },
  message: 'Not a valid phone number.'
})

extend('decimal_validation', {
  validate(value) {
    let decimalValue;
    if (isObject(value)) {
      decimalValue = value.value
    } else {
      decimalValue = value;
    }
    // Allow null / empty strings to pass
    if (!decimalValue) return true;
    const regex = /^[-]?(?:\d*\.\d{1,10}|\d+)$/;
    if (!decimalValue || !decimalValue.match(regex)) return false;
    return true;
  },
  message: 'Number must be decimal with dot separator.'
});

extend('not_null', {
  validate(value) {
    let parsedValue;
    if (isObject(value)) {
      parsedValue = value.value
    } else {
      parsedValue = value;
    }
    return !!parsedValue;
  },
  message: 'The value must not be null'
});

extend('password_validation', {
  validate(value) {
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,256}$/;
    return !(!value || !value.match(regex));
  },
  message: 'Password is not in a valid format. It must:\n' +
    '  • Be at least 8 characters long\n' +
    '  • Contain at least one lower case character\n' +
    '  • Contain at least one upper case character\n' +
    '  • Contain at least one digit\n'
});

extend('field_defined', {
  validate(value, values) {
    return values.filter(val => val === value).length < 2;
  },
  message: 'Field with name {_value_} is already defined.'
});

extend('only_one_currency_type_allowed', {
  validate(value, values) {
    return values.filter(val => val === "CURRENCY").length < 2;
  },
  message: 'You can have only one field of type Currency.'
});


extend('unique_name', {
  async validate(value) {
    const documentTypes = await window.$nuxt.$axios.$get('/api/document-types/');
    return !documentTypes.results.some((documentType) => documentType.name === value);
  },
  message: 'Document type with name {_value_} already exists.'
});

extend('unique_fields', {
  params: ['target'],
  validate(value, { target }) {
    return value !== target;
  },
  message: 'Selected gross and net amount fields must be different!'
});


extend('validate_vat_id', {
  params: ['target'],
  async validate(value, { target }) {
    if (!value) return true;
    if (EU_COUNTRIES.includes(target)) {
      let countryCode = target;
      let vatNumber = value;
      if (countryCode === "GR")
        countryCode = "EL";
      vatNumber = vatNumber.replace(countryCode, '');
      try {
        const validatedVat = await window.$nuxt.$axios.$post('/api/validate-vat-id/', { countryCode: target, vatNumber: vatNumber });
        return validatedVat.valid;
      } catch (e) {
        return true;
      }
    }
    return true;
  },
  message: 'Provided VAT ID is not valid. Check that the country code of the VAT number and selected country match.'
});


extend('equals', {
  params: ['targetValue'],
  validate(value, { targetValue }) {
    return value === targetValue;
  },
  message: 'The value is not equal to the required value'
});

extend('in_array', {
  params: ['target'],
  validate(value, { target }) {
    if (!value || !target) return false;
    if (!Array.isArray(target)) {
      return target.name === value;
    }
    return target.some(field => field.name === value);
  },
  message: 'The provided field is not valid'
})
