import Auth from '@aws-amplify/auth';
import awsConfig from '../config/amplify';
import cloneDeep from 'lodash.clonedeep';

Auth.configure(awsConfig);

export const initialState =
  process.client && window.initialState && window.Cypress
    ? cloneDeep(window.initialState)
    : {
        user: null,
        signupData: null,
        loggingIn: false,
        enabledFeatures: [],
        apiKeys: [],
      };

export const state = () => {
  return initialState;
};

export const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_SIGNUP_DATA(state, payload) {
    state.signupData = payload;
  },
  SET_LOGGING_IN(state, loggingIn) {
    state.loggingIn = loggingIn;
  },
  SET_ENABLED_FEATURES(state, payload) {
    state.enabledFeatures = payload;
  },
  SET_API_KEYS(state, payload) {
    state.apiKeys = payload;
  },
};

export const getters = {
  isAuthenticated(state) {
    return !!state.user;
  },
  isAccountlessUser(state) {
    return state.user?.services.includes('Accountless');
  },
  features(state) {
    return Object.assign(
      {},
      ...state.enabledFeatures.map((feature) => ({
        [feature.name]: feature.enabled,
      }))
    );
  },
};

export const actions = {
  async login({ commit, dispatch, state }, payload) {
    try {
      commit('SET_LOGGING_IN', true);
      await Auth.signIn(payload.email, payload.password);
      const userData = await dispatch('getUserData');
      this.$sentry.setUser({ email: state.user.email });
      window.$crisp.push(['set', 'user:nickname', [userData.email]]);
      window.$crisp.push([
        'set',
        'user:email',
        [userData.email, userData.user_hash],
      ]);
      if (userData.full_name) {
        window.$crisp.push(['set', 'user:nickname', [userData.full_name]]);
      }
      return userData;
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      commit('SET_LOGGING_IN', false);
    }
  },
  signUp({ commit }, payload) {
    commit('SET_SIGNUP_DATA', payload);
    return this.$axios.post('/api/v1/users', payload);
  },
  async logout({ commit }) {
    await Auth.signOut();
    commit('SET_USER', null);
    commit('SET_LOGGING_IN', false);
    this.$sentry.configureScope((scope) => scope.clear());
    window.$crisp.push(['do', 'session:reset']);
  },
  setLoggingInState({ commit }, loggingIn) {
    commit('SET_LOGGING_IN', loggingIn);
  },
  // eslint-disable-next-line camelcase
  confirmSignUp(_, { username, confirmation_code }) {
    return Auth.confirmSignUp(username, confirmation_code);
  },
  resetPassword(_, payload) {
    return Auth.forgotPassword(payload.email);
  },
  confirmResetPassword(_, payload) {
    return Auth.forgotPasswordSubmit(
      payload.email,
      payload.code,
      payload.new_password
    );
  },
  resendActivation(_, username) {
    return Auth.resendSignUp(username);
  },
  async changePassword(_, payload) {
    const currentUser = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(
      currentUser,
      payload.oldPassword,
      payload.newPassword
    );
  },
  async getUserData({ commit, dispatch }) {
    // eslint-disable-next-line no-unused-vars
    const [userData, userFeatures, apiKeys, _] = await Promise.all([
      this.$axios.$get('/api/v1/users/me'),
      this.$axios.$get('/api/features/'),
      this.$axios.$get('/api/v1/api-keys'),
      dispatch('billing/getUserBillingData', null, { root: true }),
    ]);

    commit('SET_API_KEYS', apiKeys.items);
    commit('SET_ENABLED_FEATURES', userFeatures);
    commit('SET_USER', userData);

    return userData;
  },
  async updateUserData({ commit }, payload) {
    const updatedUser = await this.$axios.$patch('/api/v1/users/me', payload);
    commit('SET_USER', updatedUser);
  },
  async refreshUser({ dispatch, commit }) {
    try {
      commit('SET_LOGGING_IN', true);
      await Auth.currentSession();
      const userData = await dispatch('getUserData');
      this.$sentry.setUser({ email: userData.email });
    } catch (e) {
      commit('SET_USER', null);
      commit('SET_LOGGING_IN', false);
      this.$sentry.configureScope((scope) => scope.setUser(null));
    }
  },
};
