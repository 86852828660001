
import {
  mdiFileDocument,
  mdiCog,
  mdiBookOpen,
  mdiAccount,
  mdiCreditCard,
  mdiApi,
  mdiAlphaACircle,
} from '@mdi/js';
import { mapState } from 'vuex';

export default {
  name: 'Dashboard',
  middleware: 'unauthenticated',
  data() {
    return {
      items: [
        {
          title: 'Document types',
          icon: mdiFileDocument,
          to: '/',
        },
        {
          title: 'Settings',
          active: false,
          icon: mdiCog,
          items: [
            { title: 'Profile', icon: mdiAccount, to: '/settings/profile' },
            { title: 'Billing', icon: mdiCreditCard, to: '/settings/billing' },
          ],
        },
      ],
      planTitle: {
        PAID: 'Paid plan',
        FREE: 'Free trial',
        NONE: 'No plan',
      },
      drawer: false,
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('billing', ['billingDetails']),
    pageTitle() {
      const titles = [
        ...this.items,
        { title: 'Training room', to: '/training-room/' },
        { title: 'Accountless', icon: mdiAlphaACircle, to: '/accountless' },
        { title: 'Profile', icon: mdiAccount, to: '/settings/profile' },
        { title: 'Billing', icon: mdiCreditCard, to: '/settings/billing' },
        {
          title: 'Billing details',
          icon: mdiCreditCard,
          to: '/settings/billing/details',
        },
        {
          title: 'Active plan',
          icon: mdiCreditCard,
          to: '/settings/billing/plan',
        },
      ];
      const item = titles.find((item) => item.to === this.$route.path);
      return item ? item.title : '';
    },
    userShownName() {
      if (!this.user) return '';
      if (this.user.full_name && this.user.full_name.trim()) {
        return this.user.full_name;
      } else {
        return this.user.email;
      }
    },
    planType() {
      if (this.billingDetails && this.billingDetails.subscription) {
        return this.planTitle[this.billingDetails.subscription.type];
      }
      return this.planTitle.NONE;
    },
    externalLinks() {
      return [
        {
          title: 'Guides',
          icon: mdiBookOpen,
          to:
            this.user && this.user.readme_token
              ? `https://docs.typless.com/docs?auth_token=${this.user.readme_token}`
              : 'https://docs.typless.com/docs',
        },
        {
          title: 'API reference',
          icon: mdiApi,
          to:
            this.user && this.user.readme_token
              ? `https://docs.typless.com/reference?auth_token=${this.user.readme_token}`
              : 'https://docs.typless.com/reference',
        },
      ];
    },
  },
  watch: {
    pageTitle: {
      handler(value) {
        this.drawer = value !== 'Training room';
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {
    if (this.isAccountlessUser()) {
      this.items.splice(1, 0, {
        title: 'Accountless',
        icon: mdiAlphaACircle,
        to: '/accountless',
      });
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch('user/logout');
      await this.$router.push({ path: '/login' });
    },
    isAccountlessUser() {
      return !!this.user.services.includes('Accountless');
    },
  },
};
