import cloneDeep from 'lodash.clonedeep';

const billingUrl =
  process.env.NODE_ENV === 'development' ? process.env.API_URL : '';

const stripePublicApiKey = process.env.STRIPE_PUBLIC_API_KEY || '';

export const initialState =
  process.client && window.initialState && window.Cypress
    ? cloneDeep(window.initialState)
    : {
        billingDetails: null,
        invoices: [],
        stripePublicKey: stripePublicApiKey,
      };

export const state = () => {
  return initialState;
};

export const mutations = {
  SET_BILLING_DETAILS(state, billingDetails) {
    state.billingDetails = billingDetails || null;
  },
  SET_INVOICES(state, invoices) {
    state.invoices = invoices;
  },
};

export const getters = {
  subscriptionType(state) {
    const userType =
      state.billingDetails && state.billingDetails.subscription
        ? state.billingDetails.subscription.type
        : 'FREE';
    if (!state.billingDetails || !state.billingDetails.subscription) {
      return 'No plan';
    }
    if (userType === 'FREE') {
      return 'Free plan';
    } else {
      return 'Paid plan';
    }
  },
  hasPaidSubscription(state) {
    return Boolean(state.billingDetails?.subscription);
  },
  hasUnpaidInvoices(state) {
    return state.invoices.some((invoice) => !invoice.paid);
  },
};

export const actions = {
  async getUserBillingData({ commit }) {
    const billingData = await this.$axios.$get(
      `${billingUrl}/api/v1/billing/me`
    );
    commit('SET_BILLING_DETAILS', billingData);
  },
  async updateBillingDetails({ commit }, payload) {
    const updatedBillingDetails = await this.$axios.$put(
      `${billingUrl}/api/v1/billing/billing-details`,
      payload
    );
    commit('SET_BILLING_DETAILS', updatedBillingDetails);
  },
  async updatePaymentMethod({ commit }, payload) {
    const updatedBillingDetails = await this.$axios.$put(
      `${billingUrl}/api/v1/billing/payment-method`,
      {
        payment_method_id: payload.id,
      }
    );
    commit('SET_BILLING_DETAILS', updatedBillingDetails);
    return updatedBillingDetails;
  },
  async modifySubscription({ commit, state }, extractionType) {
    const updatedBillingDetails = await this.$axios.$put(
      `${billingUrl}/api/v1/billing/subscription/paid`,
      {
        product_group: extractionType,
      }
    );
    commit('SET_BILLING_DETAILS', updatedBillingDetails);
  },
  async cancelSubscription({ dispatch }) {
    await this.$axios.$delete(
      `${billingUrl}/api/v1/billing/subscription/cancel`
    );
    await dispatch('getUserBillingData');
  },
  async fetchInvoices({ commit }) {
    const invoices = await this.$axios.$get(
      `${billingUrl}/api/v1/billing/invoices`
    );
    commit('SET_INVOICES', invoices);
  },
  retrieveInvoice(_, invoice_id) {
    return this.$axios.$get(
      `${billingUrl}/api/v1/billing/invoice/${invoice_id}`
    );
  },
  setupCard() {
    return this.$axios.$post(`${billingUrl}/api/v1/billing/setup-card`);
  },
  getCurrentBillingUsage() {
    return this.$axios.$get(`${billingUrl}/api/v1/billing/usage/current`);
  },
};
