export default async ({ store, route }) => {
  if (window.Cypress) {
    window.store = store;
  }
  await store.dispatch('user/refreshUser');
  if (route.name === 'login' && !route.query.redirect) {
    window.onNuxtReady(() => {
      window.$nuxt.$router.push('/');
      store.dispatch('user/setLoggingInState', false);
    });
  }
};
